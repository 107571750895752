// frontend\src\modules\authentication\authStore.js
// Author: Author : Andre Baldo (http://github.com/andrebaldo/) 
import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const authStore = {
    state: {
        isUserLoggedIn: false,
        isUserImpersonating: false,
        isRegistrationProcessSucceed: false,
        registrationProcessMessage: '',
        isProcessing: false,
        loginToken: '',
        impersonateToken: '',
        loginProcessMessage: '',
        verify: 0,
        verificationstatus: '',
        verificationmessage: '',
        registered: false,
        firstName: '',
        lastName: '',
        email: '',
        discord: '',
        dob: '',
        image: '',
        computers: [],
        country: 0,
        level: 0,
        dark: 1,
    },
    mutations: {
        setLogged(state, loginResult) {
            state.isUserLoggedIn = loginResult.success;
            state.loginToken = loginResult.token;
            state.loginProcessMessage = loginResult.message;
            if (loginResult.success) {
                sessionStorage.loginToken = loginResult.token;
                localStorage["loginToken"] = loginResult.token;
                this.loginToken = loginResult.token;
            } else {
                sessionStorage.removeItem("loginToken");
                localStorage.removeItem('loginToken');
            }
        },
        setUserInfo(state, result) {
            if ((result.user.blocked == 0) && (result.user.status == 1)) {
                state.firstName = result.user.firstName;
                state.lastName = result.user.lastName;
                state.email = result.user.email;
                state.image = 'https://monsteremporium.com/uploads/users/' + result.user.image;
                state.discord = result.user.discord;
                state.dob = result.user.dob;
                state.level = result.user.userlevel;
                state.country = result.user.country;
                state.computers = result.computers;
                state.isUserLoggedIn = true;
            }
        },
        setRegistrationStatus(state, result) {
            state.isRegistrationProcessSucceed = result.success;
            sessionStorage.isRegistrationProcessSucceed = result.success;
            state.registrationProcessMessage = result.message;
        },
        setIsProcessing(state, isProcessing) {
            state.isProcessing = isProcessing
        },
        setVerificationData(state, response) {
            if (response.status == 'success') {
                state.verificationstatus = true;
                state.verify = 1
            } else {
                state.verificationstatus = false;
                state.verify = 2
            }
            state.verificationmessage = response.message;
        },
        setLogout(state) {
            sessionStorage.removeItem('loginToken');
            localStorage.removeItem('loginToken');
            state.loginToken = null;
            state.isUserLoggedIn = false;
            state.level = 0;
            state.email = '';
            state.firstName = '';
            state.lastName = '';
            state.discord = '';
            state.dob = '';
            state.country = 0;
            state.image = '';
        },
        impersonateUser(state) {
            state.isUserImpersonating = true;
        },
        impersonateToken(state, response) {
            state.impersonateToken = response.token;
            sessionStorage.impersonationToken = response.token;
            localStorage["impersonationToken"] = response.token;
        },
        exitImpersonation(state) {
            sessionStorage.removeItem('impersonationToken');
            localStorage.removeItem('impersonationToken');
            state.isUserImpersonating = false;
        },
        deleteComputer(state, response) {
            let index = state.computers.findIndex(c => c.guid == response);
            state.computers.splice(index, 1);
        },
        setDarkMode(state, value) {
            state.dark = value;
        },
        setRegistered(state) {
            state.registered = true;
        }
    },
    actions: {
        async registerNewUser({ commit, dispatch }, payload) {
            commit('setIsProcessing', true);
            await axiosInstance.post('register/', payload)
                .then(function (response) {
                    commit('setIsProcessing', false);
                    let status = false;
                    if (response.data.status == 'success') {
                        status = true;
                        commit('setRegistered');
                    }
                    dispatch('toggleSnackBarvisibleAction');
                    commit('setRegistrationStatus', { success: status, message: response.data.message });

                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    dispatch('toggleSnackBarvisibleAction');
                    if (typeof error != 'undefined' && typeof error.response != 'undefined') {
                        commit('setRegistrationStatus', { success: false, message: error.response.data.message });
                    } else {
                        commit('setRegistrationStatus', { success: false, message: error.message });
                    }
                });
        },
        authenticateUserAndSetToken({ commit, dispatch }, payload) {
            return new Promise(function (resolve, reject) {
                commit('setIsProcessing', true);
                let controllerReference = payload.controllerReference;
                delete (payload.controllerReference);
                axiosInstance.post('login/', payload)
                    .then(function (response) {
                        if (response.data.success == true) {
                            commit('setIsProcessing', false);
                            commit('setLogged', { success: true, token: response.data.token, message: "Credentials accepted!" });
                            resolve(controllerReference);
                            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                            dispatch('setUserInfo')
                            dispatch('members/setNotificationType');
                            dispatch('members/setUserNotifications');
                        } else {
                            commit('setIsProcessing', false);
                            commit('setLogged', { success: false, token: response.data.token, message: response.data.message });
                            dispatch('toggleSnackBarvisibleAction');
                        }
                    })
                    .catch(function (error) {
                        commit('setIsProcessing', false);
                        if (typeof error != 'undefined' && typeof error.response != 'undefined') {
                            commit('setLogged', { success: false, message: error.response.data.message });
                        } else {
                            commit('setLogged', { success: false, message: error.message });
                        }
                        dispatch('toggleSnackBarvisibleAction');
                        reject();
                    });
            });
        },
        async setUserInfo({ commit, state }) {
            await axiosInstance.get('userinfo/')
                .then(function (response) {
                    if (state.isUserImpersonating == false) {
                        commit('setDarkMode', response.data.user.darkmode);
                    }
                    commit('setUserInfo', response.data);
                })
        },
        async setImpersonateInfo({ commit }) {
            await axiosInstance.get('userinfo/')
                .then(function (response) {
                    commit('setUserInfo', response.data);
                })
        },
        async impersonateUser({ commit, dispatch }, guid) {
            await axiosInstance.get('admin/users/impersonate/' + guid + '/')
                .then(function (response) {
                    commit('impersonateUser');
                    commit('impersonateToken', response.data);
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                    dispatch('setImpersonateInfo');
                    dispatch('members/setUserNotifications', null, { root: true });
                    router.push('/');
                })
        },
        async setImpersonateUser({ commit }) {
            commit('impersonateUser')
        },
        async exitImpersonation({ commit, dispatch }) {
            commit('exitImpersonation');
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('loginToken')}`;
            dispatch('setUserInfo')
            dispatch('members/setUserNotifications', null, { root: true });
            router.push('/admin/users/')
        },
        async setSnackbarVisibility({ commit }, isVisible) {
            commit('setIsSnackbarVisible', isVisible);
        },
        async setDarkMode({ commit }, value) {
            let mode = value ? 1 : 0
            axiosInstance.put('members/darkmode/' + mode + '/')
                .then(
                    commit('setDarkMode', mode)
                )
        },
        async checksession({ commit }) {
            await axiosInstance.get('check/')
                .then(function (response) {
                    if (response.data.status == "error") {
                        commit('setLogout');
                        router.push('/login')
                    }
                })
        },
        async deleteComputer({ commit, dispatch }, guid) {
            await axiosInstance.put('members/computer/deactivate/' + guid + '/')
                .then(response => {
                    if (response.data.status === 'success') {
                        commit('deleteComputer', response.data.guid);
                    } else {
                        let payload = { "color": false, "message": response.data.message }
                        dispatch('setSnackInfo', payload, { root: true });
                        dispatch('toggleSnackBarvisibleAction');
                    }

                })
        },
        async updateUserInfo({ commit }, payload) {
            await axiosInstance.put('members/user/data/', payload)
                .then(response => {
                    commit('updateUserInfo', response.data)
                })
        },
        async changePassword({ dispatch }, payload) {
            await axiosInstance.put('user/password/', payload)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'success') {
                        let payload = { "color": true, "message": response.data.message }
                        dispatch('setSnackInfo', payload, { root: true });
                        dispatch('toggleSnackBarvisibleAction');
                    } else {
                        let payload = { "color": false, "message": response.data.message }
                        dispatch('setSnackInfo', payload, { root: true });
                        dispatch('toggleSnackBarvisibleAction');
                    }
                })
        },
        async verifyUser({ commit, dispatch }, code) {
            await axiosInstance.post('verify/' + code + '/')
                .then(response => {
                    commit('setVerificationData', response.data);
                    if (response.data.status != 'success') {
                        dispatch('toggleSnackBarvisibleAction');
                    }
                })
        },
        async logout({ commit }) {
            commit('setIsProcessing', false);
            commit('setLogout');
        }
    },
    getters: {
        getDarkMode(state) {
            return (state.dark == 1) ? true : false
        },
        getIsRegistrationProcessSucceed(state) {
            if (state.registrationProcessMessage === '') {
                return undefined;
            } else {
                return state.isRegistrationProcessSucceed;
            }

        },
        getRegistrationProcessMessage(state) {
            return state.registrationProcessMessage;
        },
        getLoginProcessMessage(state) {
            return state.loginProcessMessage;
        },
        isProcessing(state) {
            return state.isProcessing;
        },
        getIsUserLoggedIn(state) {
            if (state.isUserLoggedIn === false) {
                if (sessionStorage.loginToken) {
                    return true;
                }
                return false;
            }
            return true;
        },
        getIsUserImpersonating(state) {
            if (state.isUserImpersonating === false) {
                if (sessionStorage.impersonationToken) {
                    return true;
                }
                return false;
            }
            return true;
        },
        getVerify(state) {
            return state.verify;
        },
        getUserName(state) {
            return state.firstName;
        },
        getUserlastName(state) {
            return state.lastName;
        },
        getFullName(state) {
            return state.firstName + " " + state.lastName;
        },
        getUserInitial(state) {
            let initial = state.firstName.slice(0, 1) + state.lastName.slice(0, 1);
            return initial;
        },
        getUserEmail(state) {
            return state.email;
        },
        getUserDiscord(state) {
            return state.discord;
        },
        getUserDOB(state) {
            return state.dob;
        },
        getUserLevel(state) {
            return state.level;
        },
        getUserImage(state) {
            return state.image
        },
        getUserCountry(state) {
            return state.country;
        },
        getUserComputers(state) {
            return state.computers;
        },
        getUserAdmin(state) {
            return (state.level >= 2) ? true : false;
        },
        getIsVerificationProcessSucceed(state) {
            return state.verificationstatus;
        },
        getVerificationProcessMessage(state) {
            return state.verificationmessage;
        },
        getRegistered(state) {
            return state.registered;
        }
    } 
}

export default authStore;