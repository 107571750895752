<template>
    <v-card>
        <v-form
            ref="form"
            @submit.prevent="changepassword"
        >
            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col class="my-0 py-0" cols="12" sm="6" md="6">
                            <v-text-field
                                v-model="form.password"
                                label="Password*"
                                type="password"
                                :rules="[rules.required, rules.password]"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="6" md="6">
                            <v-text-field
                                v-model="form.repeat"
                                label="Repeat*"
                                type="password"
                                :rules="[rules.required, (this.form.password === this.form.repeat) || 'Password must match']"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col class="my-0 py-0" cols="12">
                            <v-text-field
                                v-model="form.current"
                                label="Current*"
                                type="password"
                                :rules="[rules.required]"
                                required
                            ></v-text-field>
                        </v-col>
                   </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closesettings"
                >
                    Close
                </v-btn>
                <v-btn
                    :disabled="!formIsValid"
                    color="primary"
                    text
                    type="submit"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>


<script>
    const passwordForm = Object.freeze({
        password: '',
        repeat: '',
        current: ''
    });

    import { mapActions, mapGetters } from "vuex";

    export default {
        Name: "UserPassword",
        components: {
        },
        data: () => ({
            form: {...passwordForm},
            rules: {
                required: value => !!value || 'Required.',
                password: value => value.length >= 8 || 'Password must be at least 8 characters.'
            },
        }),
        computed: {
            formIsValid () {
                return (
                    this.form.password &&
                    this.form.repeat &&
                    this.form.current
                )
            },
            ...mapGetters([
                'GetIsSnackbarVisible'
            ]),
        },
        methods: {
            ...mapActions([
                'settingsDialog',
                'changePassword'
            ]),
            changepassword () {
                let payload = { "new": this.form.password, "repeat": this.form.repeat, "current": this.form.current };
                this.changePassword(payload);
                this.cleanpassword();
            },
            closesettings () {
                this.cleanpassword();
                this.settingsDialog(false);
            },
            cleanpassword () {
                this.form = {...passwordForm};
                this.$refs.form.resetValidation();
            },
        }
    };
</script>