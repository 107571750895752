// frontend\src\store\index.js
import Vue from 'vue'
import Vuex from 'vuex'
import UppyVuex from 'uppy-vuex'

import axiosInstance from '@/plugins/connectionBuilder.js'

import authStore from '@/modules/authentication/authStore'
import notificationStore from '@/modules/notification/notificationStore'

// Admin
import admin from './admin/admin'
import users from './admin/users'
import courses from './admin/courses'
import editcourse from './admin/editcourse'
import discounts from './admin/discounts'
import assets from './admin/assets'
import settings from './admin/settings'


// Members
import members from './members/members'
import mentor from './members/mentor'
import course from './members/course'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        uppy: UppyVuex,
        authStore,
        notificationStore,
        admin,
        courses,
        users,
        editcourse,
        discounts,
        assets,
        settings,
        members,
        mentor,
        course
    },
    state: {
        drawer: false,
        settingsdialog: false,
        snackColor: '',
        snackText: '',
        geo: [],
        terms: '',
        link: null,
    },
    mutations: {
        setDrawer(state, value) {
            state.drawer = value;
        },
        settingsDialog(state, value) {
            state.settingsdialog = value;
        },
        setSnackInfo(state, value) {
            state.snackColor = value.color;
            state.snackText = value.message;
        },
        setGeoData(state, response) {
            state.geo = response;
        },
        setTermsData(state, response) {
            state.terms = response;
        },
        setLink(state, data) {
            state.link = data;
        }
    },
    actions: {
        setDrawer({ commit }, value) {
            commit('setDrawer', value);
        },
        settingsDialog({ commit }, value) {
            commit('settingsDialog', value);
        },
        setSnackInfo({ commit }, value) {
            commit('setSnackInfo', value);
        },
        setLink({ commit }, value) {
            commit('setLink', value);
        },
        async getGeoData({ commit }) {
            await axiosInstance.get('geo/')
                .then(response => {
                    commit('setGeoData', response.data);
                })
        },
        async getTermsData({ commit }) {
            await axiosInstance.get('terms/')
                .then(response => {
                    commit('setTermsData', response.data);
                })
        },
    },
    getters: {
        getSettingsDialog(state) {
            return state.settingsdialog;
        },
        getDrawer(state) {
            return state.drawer;
        },
        getSnackText(state) {
            return state.snackText;
        },
        getSnackColor(state) {
            return state.snackColor;
        },
        getLink(state) {
            return state.link;
        }
    }
})

export default store;