import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const settings = {
    namespaced: true,
    state: () => ({
        name: '',
        version: 0,
        privacy: '',
        terms: '',
        mailtemplates: [],
        services: [],
    }),

    mutations: {
        setSettingsData(state, response) {
            console.log(response);
            state.name = response.settings.name;
            state.version = response.settings.version;
            state.privacy = response.settings.privacy;
            state.terms = response.settings.terms;
            state.services = response.services;
            state.mailtemplates = response.mail;
        },
        updatePrivacy(state, value) {
            state.privacy = value;
        },
        updateTerms(state, value) {
            state.terms = value;
        },
        addAPI(state, value) {
            state.services.push(value);
        },
        newMailTemplate(state, response) {
            state.mailtemplates.push(response);
        },
        updateMailTemplate(state, response) {
            let index = state.mailtemplates.findIndex(obj => obj.guid == response.guid);
            state.mailtemplates.splice(index, 1, response);
        }
    },
    actions: {
        async getSettingsData({ commit }) {
            await axiosInstance.get('admin/settings/')
                .then(response => {
                    commit('setSettingsData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async savePrivacy({ state }) {
            await axiosInstance.put('admin/settings/privacy/', state.privacy)
        },
        async saveTerms({ state }) {
            await axiosInstance.put('admin/settings/terms/', state.terms)
        },
        async newMailTemplate({ commit }, payload) {
            await axiosInstance.post('admin/settings/mailtemplate/new/', payload)
                .then(response => {
                    commit('newMailTemplate', response.data);
                })
        },
        async updateMailTemplate({ commit }, payload) {
            await axiosInstance.put('admin/settings/mailtemplate/update/', payload)
                .then(response => {
                    commit('updateMailTemplate', response.data);
                })
        },
        async createAPI({ commit }, name) {
            await axiosInstance.post('admin/settings/service/newapi/', name)
                .then(response => {
                    commit('addAPI', response.data);
                })
        },
        async sendTestEmail(_, payload) {
            await axiosInstance.post('admin/settings/testmail/', payload)
        }
    }
}

export default settings
