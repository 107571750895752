<!-- 
frontend\src\modules\notification\SnackNotification.vue
-->
<template>
    <v-snackbar
        v-model="visibility"
        centered
        :color="colorCondition ? 'success' : 'error'"
        :timeout="-1"
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                @click.native="setSnackbarVisibility(false)"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: 'SnackNotification',
        props: ["colorCondition", "message"],
        data: () => ({
        }),
        computed: {
            ...mapGetters(["GetIsSnackbarVisible"]),
            visibility: {
                /* By default get() is used */
                get() {
                    return this.GetIsSnackbarVisible
                },
            }
        },
        methods: {
            ...mapActions(["setSnackbarVisibility"])
        }
    };
</script>