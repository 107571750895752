import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const discounts = {
    namespaced: true,
    state: () => ({
        discounts: [],
    }),

    mutations: {
        getDiscountData(state, response) {
            state.discounts = response;
        },
        addDiscount(state, response) {
            state.discounts.push(response);
        },
        deleteDiscount(state, guid) {
            let index = state.discounts.findIndex(obj => obj.guid == guid);
            state.discounts.splice(index, 1)
        }
    },
    actions: {
        async getDiscountData({ commit }) {
            await axiosInstance.get('admin/discounts/')
                .then(response => {
                    commit('getDiscountData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async addDiscount({ commit }, payload) {
            await axiosInstance.post('admin/discounts/add/', payload)
                .then(response => {
                    commit('addDiscount', response.data);
                })
        },
        async deleteDiscount({ commit }, guid) {
            await axiosInstance.delete('admin/discounts/delete/' + guid + '/')
                .then(
                    commit('deleteDiscount', guid)
                )
        }
    }
}

export default discounts