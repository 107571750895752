import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const admin = {
    namespaced: true,
    state: () => ({
        courses: [],
        assets: [],
        resources: [],
        emaildialog: false,
        emailrecipient: ''
    }),

    mutations: {
        toggleEmailDialog(state, recipient) {
            state.emailrecipient = recipient;
            state.emaildialog = !state.emaildialog;
        },
        setDashData(state, response) {
            state.courses = response.courses;
            state.assets = response.assets;
        },
    },
    actions: {
        async getDashData({ commit }) {
            await axiosInstance.get('/admin/')
                .then(response => {
                    commit('setDashData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async sendEmail(_, payload) {
            await axiosInstance.post('admin/sendemail/', payload);
        },
        async toggleEmailDialog({ commit }, recipient) {
            commit('toggleEmailDialog', recipient);
        },
    }
}

export default admin