import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const assets = {
    namespaced: true,
    state: () => ({
        assets: [],
        asset_type: [],
        asset_family: [],
        asset_group: [],
        family_info: "",
        assetdialog: false,
        familydialog: false,
        typedialog: false,
        uploaddialog: false,
        assetedit: false,
        familyedit: false,
        typeedit: false,
        overlay: false,
        progress: 0,
        selectedassetname: '',
        selectedFile: [],
        selectedguid: null,
        selectedZip: [],
        selectedfamily: null,
        selectedtype: null,
        selectedversion: 1,
        familypublic: false,
        selectedstatus: false,
        newtype: null,
        newfamily: null,
        familyselectedgroup: null,
        familyselectedFile: [],
        familymime_type: '',
        familycropedImage: '',
        uploadassetguid: ''
    }),

    mutations: {
        setAssetsData(state, response) {
            state.assets = response.assets;
            state.asset_type = response.asset_type;
            state.asset_family = response.asset_family;
            state.asset_group = response.asset_group;
        },
        toggleOverlay: (state) => {
            return state.overlay = !state.overlay;
        },
        toggleAssetDialog: (state) => {
            return state.assetdialog = !state.assetdialog;
        },
        toggleFamilyDialog: (state) => {
            return state.familydialog = !state.familydialog;
        },
        toggleTypeDialog: (state) => {
            return state.typedialog = !state.typedialog;
        },
        toggleUploadDialog: (state) => {
            return state.uploaddialog = !state.uploaddialog;
        },
        setAssetEdit: (state, bool) => {
            return state.assetedit = bool;
        },
        setFamilyEdit: (state, bool) => {
            return state.familyedit = bool;
        },
        setTypeEdit: (state, bool) => {
            return state.typeedit = bool;
        },
        editAssetDialog(state, payload) {
            state.selectedassetname = payload.name;
            state.selectedguid = payload.guid;
            state.selectedfamily = payload.family_guid;
            state.selectedtype = payload.type_guid;
            state.selectedversion = payload.version;
            state.selectedstatus = payload.status ? true : false;
        },
        editFamilyDialog(state) {
            let row = state.asset_family.filter(f => f.guid == state.selectedfamily);
            state.newfamily = row[0].name;
            state.familyselectedgroup = row[0].group_guid;
            state.familypublic = row[0].show_public ? true : false;
            state.familycropedImage = "https://monsteremporium.com/uploads/assets/" + row[0].image;
        },
        editTypeDialog(state) {
            let row = state.asset_type.filter(t => t.guid == state.selectedtype);
            state.newtype = row[0].name;
        },
        cleanAssetDialog(state) {
            state.selectedassetname = ''
            state.selectedFile = [];
            state.selectedZip = [];
            state.selectedfamily = null;
            state.selectedtype = null;
            state.selectedversion = 1;
            state.selectedstatus = false;
        },
        cleanFamilyDialog(state) {
            state.newfamily = null;
            state.familyselectedgroup = null;
            state.familyselectedFile = [];
            state.familymime_type = '';
            state.familycropedImage = '';
            state.familypublic = false;
        },
        cleanUploadDialog(state) {
            state.selectedZip = [];
        },
        cleanTypeDialog(state) {
            state.newtype = null;
        },
        updateSelectedAssetName(state, value) {
            state.selectedassetname = value;
        },
        updateSelectedFamily(state, value) {
            state.selectedfamily = value;
        },
        updateSelectedType(state, value) {
            state.selectedtype = value;
        },
        updateSelectedGuid(state, value) {
            state.selectedguid = value;
        },
        updateSelectedZip(state, value) {
            state.selectedZip = value;
        },
        updateSelectedFile(state, value) {
            state.selectedFile = value;
        },
        updateCropedImage(state, value) {
            state.cropedImage = value;
        },
        updateMimeType(state, value) {
            state.mime_type = value;
        },
        updateSelectedVersion(state, value) {
            state.selectedversion = value;
        },
        updateFamilyPublic(state, value) {
            state.familypublic = value;
        },
        updateSelectedStatus(state, value) {
            state.selectedstatus = value;
        },
        updateNewType(state, value) {
            state.newtype = value;
        },
        updateNewFamily(state, value) {
            state.newfamily = value;
        },
        updateFamilySelectedGroup(state, value) {
            state.familyselectedgroup = value;
        },
        updateFamilySelectedFile(state, value) {
            state.familyselectedFile = value;
        },
        updateFamilyMimeType(state, value) {
            state.familymime_type = value;
        },
        updateFamilyCropedImage(state, value) {
            state.familycropedImage = value;
        },
        uploadAssetDialog(state, guid) {
            state.uploadassetguid = guid;
        },
        addAsset(state, response) {
            state.assets.push(response);
            state.progress = 0;
        },
        addFamily(state, response) {
            state.asset_family.push(response);
            state.selectedfamily = response.guid;
        },
        addType(state, response) {
            state.asset_type.push(response);
            state.selectedtype = response.guid;
        },
        editAsset(state) {
            let index = state.assets.findIndex(obj => obj.guid == state.selectedguid);
            state.assets[index].name = state.selectedassetname;
            state.assets[index].family_guid = state.selectedfamily;
            state.assets[index].type_guid = state.selectedtype;
            state.assets[index].version = state.selectedversion;
            state.assets[index].status = state.selectedstatus;
        },
        editFamily(state) {
            let index = state.asset_family.findIndex(obj => obj.guid == state.selectedfamily);
            state.asset_family[index].name = state.newfamily;
            state.asset_family[index].group_guid = state.familyselectedgroup;
            state.asset_family[index].show_public = state.familypublic;
        },
        editType(state) {
            let index = state.asset_type.findIndex(obj => obj.guid == state.selectedtype);
            state.asset_type[index].name = state.newtype;
        },
        uploadAsset(state, version) {
            let index = state.assets.findIndex(obj => obj.guid == state.uploadassetguid);
            state.assets[index].version = version;
            state.uploadassetguid = ''
        },
        deleteAsset(state, index) {
            state.assets.splice(index, 1);
        },
        deleteFamily(state) {
            let index = state.asset_family.findIndex(t => t.guid == state.selectedfamily);
            state.asset_family.splice(index, 1);
            state.selectedfamily = null;
        },
        deleteType(state) {
            let index = state.asset_type.findIndex(t => t.guid == state.selectedtype);
            state.asset_type.splice(index, 1);
            state.selectedtype = null;
        },
        updateProgress(state, progress) {
            state.progress = progress;
        }
    },
    actions: {
        async getAssetsData({ commit }) {
            await axiosInstance.get('admin/assets/')
                .then(response => {
                    commit('setAssetsData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async toggleAssetDialog({ commit }) {
            commit('toggleAssetDialog');
        },
        async toggleFamilyDialog({ commit }) {
            commit('toggleFamilyDialog');
        },
        async toggleTypeDialog({ commit }) {
            commit('toggleTypeDialog');
        },
        async toggleUploadDialog({ commit }) {
            commit('toggleUploadDialog');
        },
        async setAssetEdit({ commit }, bool) {
            commit('setAssetEdit', bool);
        },
        async setFamilyEdit({ commit }, bool) {
            commit('setFamilyEdit', bool);
        },
        async setTypeEdit({ commit }, bool) {
            commit('setTypeEdit', bool);
        },
        async editAssetDialog({ commit }, payload) {
            commit('editAssetDialog', payload);
            commit('setAssetEdit', true);
            commit('toggleAssetDialog');
        },
        async editFamilyDialog({ commit }) {
            commit('editFamilyDialog');
            commit('setFamilyEdit', true);
            commit('toggleFamilyDialog');
        },
        async editTypeDialog({ commit }) {
            commit('editTypeDialog');
            commit('setTypeEdit', true);
            commit('toggleTypeDialog');
        },
        async uploadAssetDialog({ commit }, item) {
            commit('uploadAssetDialog', item.guid);
            commit('toggleUploadDialog');
        },
        async cleanAssetDialog({ commit }) {
            commit('cleanAssetDialog');
        },
        async cleanFamilyDialog({ commit }) {
            commit('cleanFamilyDialog');
        },
        async cleanTypeDialog({ commit }) {
            commit('cleanTypeDialog');
        },
        async cleanUploadDialog({ commit }) {
            commit('cleanUploadDialog');
        },
        async addAsset({ commit, state }) {
            commit('toggleOverlay');
            let formData = new FormData();
            formData.append('file', state.selectedZip);
            formData.append('name', state.selectedassetname);
            formData.append('family_guid', state.selectedfamily);
            formData.append('type_guid', state.selectedtype);
            formData.append('version', state.selectedversion);
            formData.append('status', state.selectedstatus ? 1 : 0);
            commit('toggleAssetDialog');
            await axiosInstance.post('admin/assets/create/', formData, {
                onUploadProgress: function (progressEvent) {
                    state.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(state)
            }).then(response => {
                commit('addAsset', response.data);
                commit('toggleOverlay');
                commit('cleanAssetDialog');
            }).catch(error => {
                console.log(error)
            })
        },
        async addFamily({ commit, state }) {
            let payload = { "group_guid": state.familyselectedgroup, "name": state.newfamily, "image": state.familycropedImage, "show_public": state.familypublic ? 1 : 0 };
            commit('toggleFamilyDialog');
            await axiosInstance.post('admin/assets/family/create/', payload)
                .then(response => {
                    commit('addFamily', response.data);
                    commit('cleanFamilyDialog');
                })
        },
        async addType({ commit, state }) {
            let payload = { "name": state.newtype };
            commit('toggleTypeDialog');
            await axiosInstance.post('admin/assets/type/create/', payload)
                .then(response => {
                    commit('addType', response.data);
                    commit('cleanTypeDialog');
                })
        },
        async uploadAsset({ commit, state }) {
            commit('toggleOverlay');
            let formData = new FormData();
            formData.append('file', state.selectedZip);
            formData.append('guid', state.uploadassetguid);
            commit('toggleUploadDialog');
            await axiosInstance.put('admin/assets/upload/', formData, {
                onUploadProgress: function (progressEvent) {
                    state.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(state)
            }).then(response => {
                commit('uploadAsset', response.data);
                commit('toggleOverlay');
                commit('cleanUploadDialog');
            }).catch(error => {
                console.log(error)
            })
        },
        async editAsset({ commit, state }) {
            let statusstat = 0;
            if (state.selectedstatus == true) {
                statusstat = 1;
            }
            let payload = { "guid": state.selectedguid, "name": state.selectedassetname, "family_guid": state.selectedfamily, "type_guid": state.selectedtype, "version": state.selectedversion, "status": statusstat };
            await axiosInstance.put('admin/assets/update/', payload)
            commit('editAsset');
            commit('cleanAssetDialog');
            commit('toggleAssetDialog');
        },
        async editFamily({ commit, state }) {
            let payload = { "guid": state.selectedfamily, "name": state.newfamily, "group_guid": state.familyselectedgroup, "show_public": state.familypublic ? 1 : 0 };
            await axiosInstance.put('admin/assets/family/update/', payload);
            commit('editFamily');
            commit('cleanFamilyDialog');
            commit('toggleFamilyDialog');
        },
        async editType({ commit, state }) {
            let payload = { "guid": state.selectedtype, "name": state.newtype };
            await axiosInstance.put('admin/assets/type/update/', payload);
            commit('editType');
            commit('cleanTypeDialog');
            commit('toggleTypeDialog');
        },
        async deleteAsset({ commit, state }, index) {
            let guid = state.assets[index].guid;
            await axiosInstance.delete('admin/assets/delete/' + guid + '/')
                .then(
                    commit('deleteAsset', index)
                )
            commit('cleanAssetDialog');
        },
        async deleteFamily({ commit, state }) {
            await axiosInstance.delete('admin/assets/family/delete/' + state.selectedfamily + '/')
                .then(
                    commit('deleteFamily')
                )
            commit('cleanFamilyDialog');
        },
        async deleteType({ commit, state }) {
            await axiosInstance.delete('admin/assets/type/delete/' + state.selectedtype + '/')
                .then(
                    commit('deleteType')
                )
            commit('cleanTypeDialog');

        },
    }
}

export default assets