<template>
    <v-dialog
        v-model="settingsDialog"
        max-width="600px"
        overlay-color="secondary"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-icon>
                    <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
        </template>

       <v-card>
            <v-tabs
                v-model="settingstabs"
                fixed-tabs
            >
                <v-tab>Profile</v-tab>
                <v-tab>Password</v-tab>
                <!--<v-tab>Settings</v-tab>-->

                <v-tab-item>
                    <UserProfile/>
                </v-tab-item>
                <v-tab-item>
                    <UserPassword/>
                </v-tab-item>
                <v-tab-item>
                    <PersonalSettings/>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <SnackNotification
            v-bind:colorCondition="getSnackColor"
            v-bind:message="getSnackText"
        />
    </v-dialog>
</template>

<script>
    import UserProfile from '@/components/core/UserProfile.vue'
    import UserPassword from '@/components/core/UserPassword.vue'
    import PersonalSettings from '@/components/core/PersonalSettings.vue'

    import SnackNotification from "@/modules/notification/SnackNotification.vue";

    import { mapGetters } from "vuex";

    export default {
        name: "UserSettings",
        components: {
            UserProfile,
            UserPassword,
            PersonalSettings,
            SnackNotification
        },
        data: () => ({
            settingstabs: ''
        }),
        computed: {
            ...mapGetters([
                'GetIsSnackbarVisible',
                'getSnackColor',
                'getSnackText'
            ]),
            settingsDialog: {
                get () {
                    return this.$store.state.settingsdialog
                },
                set (value) {
                    this.$store.commit('settingsDialog', value)
                }
            },
        },
        methods: {
        }
    };
</script>