<!-- 
members\src\components\Navbar.vue
-->
<template>
    <v-app-bar app dark clipped-left :color="getColor()">
        <v-toolbar-title>
            <a href="https://monsteremporium.com/">
                <v-img class="hidden-md-and-down" alt="MonsterEmporium" max-width="155"
                    src="@/assets/melogohorizontal.png" />
                <v-img class="hidden-lg-and-up" alt="MonsterEmporium" max-width="32" src="@/assets/melogo.png" />
            </a>
        </v-toolbar-title>

        <v-app-bar-nav-icon
            v-if="getUserLevel >= 4 || $route.name == 'course' || $route.name == 'assets' || $route.name == 'resources' || $route.name == 'week' || $route.name == 'mentor' || $route.name == 'mentorweek'"
            class="hidden-lg-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-container fluid>
            <v-row height="100" class="d-flex align-center">
                <v-spacer></v-spacer>
                <UserNotification />
                <v-icon
                    v-if="($route.name == 'course' || $route.name == 'assets' || $route.name == 'resources' || $route.name == 'week' || $route.name == 'mentor' || $route.name == 'mentorweek')"
                    class="mr-3 pb-1" @click="$router.push('/')">
                    mdi-home
                </v-icon>

                <div class="pr-2" v-if="getIsUserLoggedIn">
                    <v-menu class="toolbar-menu-item ma-0 pa-0" offset-y origin="center center" :nudge-bottom="10"
                        transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon large text slot="activator" :ripple="false" v-bind="attrs" v-on="on">
                                <v-avatar color="primary darken-1" size="42px">
                                    {{ getUserInitial }}
                                </v-avatar>
                            </v-btn>
                        </template>
                        <v-card class="pa-0 ma-0" width="200">
                            <v-list-item-content class="justify-center mb-0 pb-0">
                                <div class="mx-auto text-center pa-0">
                                    <h3>{{ getFullName }}</h3>
                                    <p class="text-caption mt-1">
                                        {{ getUserEmail }}
                                    </p>
                                    <v-divider></v-divider>
                                    <v-list class="ma-0 pa-0">
                                        <v-list-item class="pt-4 mb-0">
                                            <v-layout column align-center>
                                                <v-switch prepend-icon="mdi-white-balance-sunny"
                                                    append-icon="mdi-moon-waning-crescent" v-model="dark"
                                                    class="pa-0 ma-0"></v-switch>
                                            </v-layout>
                                        </v-list-item>
                                        <UserSettings />
                                        <v-list-item v-if="getIsUserImpersonating" @click="exitImpersonation()">
                                            <v-list-item-icon>
                                                <v-icon>mdi-logout</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Exit</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-else @click="processLogout()">
                                            <v-list-item-icon>
                                                <v-icon>mdi-logout</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Logout</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-menu>
                </div>
            </v-row>
        </v-container>
    </v-app-bar>
</template>
<script>
import UserNotification from '@/components/core/Notification.vue'
import UserSettings from '@/components/core/Settings.vue'

import { mapGetters, mapActions } from "vuex";

export default {
    name: "MainNavbar",
    components: {
        UserNotification,
        UserSettings,
    },
    computed: {
        drawer: {
            get() {
                return this.$store.getters.getDrawer
            },
            set(value) {
                this.$store.dispatch('setDrawer', value)
            }
        },
        dark: {
            get() {
                return this.$store.getters.getDarkMode
            },
            set(value) {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                this.$store.dispatch('setDarkMode', value)
            }
        },
        ...mapGetters([
            'getIsUserLoggedIn',
            'getUserName',
            'getFullName',
            'getUserInitial',
            'getUserEmail',
            'getUserImage',
            'getUserLevel',
            'getNotifications',
            'getNotificationIcon',
            'getNotificationName',
            'getNotificationLink',
            'getIsUserImpersonating'
        ])
    },
    methods: {
        ...mapActions([
            'logout',
            'exitImpersonation'
        ]),
        clickNotification(id, link) {
            let path = this.getNotificationLink(id) + link + "#applicants"
            this.$router.push(path)
        },
        notificationTime(date) {
            let now = new Date();
            let check = new Date(date);
            let time;
            // calculate the time difference of two dates JavaScript
            var diffTime = (now.getTime() - check.getTime());

            if (diffTime >= 604800) {
                time = parseInt(diffTime / (1000 * 604800)) + " weeks";
            }
            else if (diffTime >= 86400) {
                time = parseInt(diffTime / (1000 * 86400)) + " days";
            }
            else if (diffTime >= 3600) {
                time = parseInt(diffTime / (1000 * 3600)) + " hours";
            }
            else if (diffTime >= 60) {
                time = parseInt(diffTime / (1000 * 60)) + " minutes";
            }
            else {
                time = parseInt(diffTime / (1000)) + " seconds";
            }

            return time
        },
        getColor() {
            if (this.getIsUserImpersonating) {
                return "#4A148C"
            }
        },
        processLogout() {
            this.logout()
                .then(
                    this.$router.push('/login')
                )
        },
    }
};
</script>