import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'
import axios from 'axios';

const mentor = {
    namespaced: true,
    state: () => ({
        guid: '',
        students: [],
        submissions: [],
        feedback: [],
        upload: false,
        uploadprogress: 0,
    }),

    mutations: {
        setGuid(state, guid) {
            state.guid = guid;
        },
        setMentorData(state, response) {
            state.students = response.students;
            state.liveclass = '';
            state.submissions = response.submissions;
            state.feedback = response.feedback;
        },
        addFeedback(state, response) {
            state.feedback.push(response);
        },
        deleteFeedback(state, guid) {
            let index = state.feedback.findIndex(t => t.guid == guid);
            state.feedback.splice(index, 1);
        },
        cleanWeek(state) {
            state.liveclass = '';
        },
    },
    actions: {
        async setGuid({ commit }, guid) {
            commit('setGuid', guid);
        },
        async setMentorData({ commit, dispatch, state }, weekid) {
            await axiosInstance.get('members/mentor/' + state.guid + '/')
                .then(response => {
                    commit('setMentorData', response.data)
                    dispatch('course/setGuid', state.guid, { root: true });
                    dispatch('course/getCourseData', weekid, { root: true });
                    dispatch('getLiveClass', weekid);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async getLiveClass({ dispatch, state }, weekid) {
            await axiosInstance.get('bbb/get/' + state.guid + '/' + weekid + '/')
                .then(response => {
                    console.log(response)
                    dispatch('course/setLiveLink', response.data, { root: true });
                })
        },
        async startLiveClass({ dispatch, state }, weekguid) {
            await axiosInstance.get('bbb/start/' + state.guid + '/' + weekguid + '/')
                .then(response => {
                    dispatch('course/setLiveLink', response.data, { root: true });
                })
        },
        async newWeek({ commit }) {
            commit('cleanWeek');
        },
        async newFeedback({ commit, state }, payload) {
            state.upload = true;
            const file = payload.file;

            let data = { "file_name": file.name, "file_type": file.type, "group_guid": state.guid, "student_guid": payload.student_guid, "week_guid": payload.week_guid, "mentorComment": payload.comment };

            console.log(data)

            axiosInstance.post('members/mentor/feedback/', data)
            .then(async response => {
                try {
                    const upload = await axios.put(response.data.url, file, {
                      headers: {
                        'Content-Type': file.type,
                      },
                    });
                    console.log('Successfully uploaded file:', upload);
                } catch (error) {
                    console.error('Error uploading file:', error);
                    throw error;
                }
                commit('addFeedback', response.data.feedback);
                state.upload = false;
                state.uploadprogress = 0;
            }).catch(error => {
                console.log(error)
            })
        },
        async deleteFeedback({ commit }, guid) {
            await axiosInstance.delete('members/mentor/feedback/delete/' + guid + '/')
                .then(
                    commit('deleteFeedback', guid)
                )
        },
    },
    getters: {
    }
}

export default mentor