<!-- 
frontend\src\views\members\MemberView.vue
-->
<template>
    <v-container fluid class="pa-0 ma-0">
        <router-view></router-view>
    </v-container>
</template>

<script>
    export default {
        name: 'MemberView',
        components: {
        
        }
    }
</script>