import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const course = {
    namespaced: true,
    state: () => ({
        guid: '',
        weekguid: '',
        name: '',
        start: '',
        group: {},
        deposit: 0,
        fullprice: 0,
        assignmnets: [],
        installmentprice: 0,
        installmentcount: 0,
        studentprice: 0,
        studentinstallments: 0,
        stripe: '',
        application: [],
        paymentoptions: [],
        precourse: '',
        introstream: '',
        duration: 0,
        currentweek: 0,
        assets: [],
        asset_family: [],
        asset_type: [],
        weeks: [],
        week: [],
        demo_modules: [],
        demo_categories: [],
        demos: [],
        resources: [],
        liveclass: '',
        syncsketch: [],
        feedback: [],
        feedback_video: '',
        students: [],
        videostatus: [],
        currentvideo: '',
        statuscode: 0,
        payment: [],
        mentor: false
    }),

    mutations: {
        clean(state) {
            state.weekguid = '';
            state.name = '';
            state.start = '';
            state.group = {};
            state.deposit = 0;
            state.fullprice = 0;
            state.assignmnets = [];
            state.installmentprice = 0;
            state.installmentcount = 0;
            state.studentprice = 0;
            state.studentinstallments = 0;
            state.stripe = '';
            state.application = [];
            state.paymentoptions = [];
            state.paymentguid = '';
            state.precourse = '';
            state.introstream = '';
            state.duration = 0;
            state.currentweek = 0;
            state.assets = [];
            state.asset_family = [];
            state.asset_type = [];
            state.weeks = [];
            state.videostatus = [];
            state.statuscode = 0;
            state.payment = [];
            state.students = [];
            state.resources = [];
            state.mentor = false;
        },
        setGuid(state, guid) {
            state.guid = guid;
        },
        setWeekGuid(state, week) {
            let guid = state.weeks.filter(w => w.weekCourseId == week).map(w => w.guid)
            state.weekguid = guid[0]
        },
        setCourseData(state, response) {
            console.log(response)
            state.name = response.course.name;
            state.group = response.group;
            state.application = response.application;
            state.currentweek = response.currentweek;
            state.precourse = response.course.pre_course_info;
            state.introstream = response.course.stream;
            state.duration = response.course.duration;
            state.start = response.class.start;
            state.deposit = response.course.deposit;
            state.fullprice = response.course.price;
            state.studentprice = response.price;
            state.studentinstallments = response.installments;
            state.installmentprice = response.course.installmentprice;
            state.installmentcount = response.course.installmentcount;
            state.stripe = response.course.stripe;
            state.paymentoptions = response.paymentoptions;
            state.statuscode = response.status.code;
            state.weeks = response.weeks;
            state.asset_family = response.asset_family;
            state.asset_type = response.asset_type;
            state.resources = response.resources;
            state.videostatus = response.videostatus;
            state.payment = response.payment;
            state.paymentguid = response.paymentguid;
            state.mentor = response.mentor;
            state.students = response.students;
        },
        cleanWeek(state) {
            state.week = [];
            state.liveclass = '';
            state.demo_modules = [];
            state.demo_categories = [];
            state.syncsketch = [];
            state.feedback = [];
            state.demos = [];
        },
        setWeekData(state, response) {
            state.week = response.week;
            state.liveclass = response.liveclass;
            state.demo_modules = response.demo_modules;
            state.demo_categories = response.demo_categories;
            state.syncsketch = response.syncsketch;
            state.feedback = response.feedback;
        },
        setLiveLink(state, response) {
            state.liveclass = response;
        },
        setModuleDemos(state, response) {
            let video = null
            response.forEach(
                r => {
                    if (video == null) {
                        let index = state.videostatus.findIndex(obj => obj.videoid == r.stream)
                        if (index != -1) {
                            if (state.videostatus[index].finished != 1) {
                                video = r.stream
                            }
                        }
                    }
                }
            )
            if (video == null) {
                state.currentvideo = response[0].stream
            } else {
                state.currentvideo = video
            }
            state.demos = response;
        },
        setModuleAssets(state, response) {
            state.assets = response;
        },
        newVideoStatus(state, response) {
            state.videostatus.push(response);
        },
        updateVideoStatus(state, response) {
            let index = state.videostatus.findIndex(obj => obj.guid == response.guid);
            state.videostatus[index].progress = response.progress;
            state.videostatus[index].lastviewed = response.lastviewed;
            state.videostatus[index].finished = response.finished;
        },
        setCurrentVideo(state, videoid) {
            state.currentvideo = videoid;
        },
        addSubmission(state, response) {
            let index = state.weeks.findIndex(obj => obj.guid == response.week_guid);
            state.weeks[index].assignment_guid = response.guid;
        },
        paymentLoading(state) {
            state.statuscode = 2;
        },
        setFeedbackVideo(state, response) {
            state.feedback_video = response;
        }
    },
    actions: {
        async setGuid({ commit }, guid) {
            commit('setGuid', guid);
        },
        async getCourseData({ commit, state }, week) {
            await axiosInstance.get('members/course/' + state.guid + '/')
                .then(response => {
                    commit('clean');
                    commit('setCourseData', response.data);
                    if (response.data.status.code != 0) {
                        console.log(response.data.status);
                    }
                    if ((response.data.status.code) == 0 && (week >= 1)) {
                        commit('setWeekGuid', week);
                    }
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async getWeekData({ commit }, guid) {
            await axiosInstance.get('members/course/' + guid.group + '/week/' + guid.week + '/')
                .then(response => {
                    commit('cleanWeek');
                    commit('setWeekData', response.data);
                    commit('setCurrentVideo', response.data.week.stream);
                })
        },
        async getFeedback({ commit }, guid) {
            await axiosInstance.get('members/course/feedback/' + guid + '/')
                .then(response => {
                    commit('setFeedbackVideo', response.data);
                })
        },
        async getModuleDemos({ commit, state }, guid) {
            await axiosInstance.get('members/course/' + state.guid + '/demo/module/' + guid + '/')
                .then(response => {
                    commit('setModuleDemos', response.data);
                })
        },
        async getModuleAssets({ commit, state }, guid) {
            await axiosInstance.get('members/course/' + state.guid + '/assets/module/' + guid + '/')
                .then(response => {
                    commit('setModuleAssets', response.data);
                })
        },
        async newVideoStatus({ commit }, payload) {
            axiosInstance.post('members/course/videostatus/new/', payload)
                .then(response => {
                    commit('newVideoStatus', response.data);
                })
        },
        async updateVideoProgress({ commit }, payload) {
            axiosInstance.put('members/course/videostatus/update/progress/', payload)
                .then(
                    commit('updateVideoProgress', payload)
                )
        },
        async getPayment({ dispatch }, guid) {
            axiosInstance.get('members/course/payment/' + guid + '/')
                .then(response => {
                    if (response.data.status == "success") {
                        dispatch('getCourseData')
                    } else {
                        console.log(response.data.message)
                    }
                })
        },
        async setCurrentVideo({ commit }, videoid) {
            commit('setCurrentVideo', videoid)
        },
        async paymentLoading({ commit }) {
            commit('paymentLoading')
        },
        async addDiscount({ dispatch }, code) {
            axiosInstance.post('members/course/discount/', code)
                .then(response => {
                    if (response.data.status == "success") {
                        dispatch('getCourseData')
                    } else {
                        console.log(response.data.message)
                    }
                })

        },
        async setLiveLink({ commit }, payload) {
            commit('setLiveLink', payload);
        },
        async newSubmission({ commit, state }, payload) {
            state.upload = true;
            let formData = new FormData();
            formData.append('file', payload.file);
            formData.append('group_guid', state.guid);
            formData.append('week_guid', payload.week_guid);
            formData.append('studentComment', payload.comment);
            axiosInstance.post('members/course/submission/', formData,
                {
                    onUploadProgress: function (progressEvent) {
                        state.uploadprogress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(state)
                }
            ).then(response => {
                commit('addSubmission', response.data);
                state.upload = false;
                state.uploadprogress = 0;
            }).catch(error => {
                console.log(error)
            })
        },
    },
    getters: {
        get_current_video(state) {
            return state.currentvideo;
        }
    }
}

export default course