<template>
    <v-navigation-drawer
        v-if="getUserLevel >= 4 && $route.name !== 'course' && $route.name !== 'assets' && $route.name !== 'resources' && $route.name !== 'week' && $route.name !== 'mentor' && $route.name !== 'mentorweek'"
        v-model="drawer" :mini-variant="mini" :permanent="permanent" clipped app>
        <v-list-item class="px-2">
            <v-subheader class="text-bold" v-if="mini == false">
                Admin
            </v-subheader>
            <v-row height="100" class="d-flex align-center mr-2">
                <v-spacer></v-spacer>
                <v-btn icon @click.stop="mini = !mini">
                    <v-icon v-if="mini == false">mdi-chevron-left</v-icon>
                    <v-icon v-if="mini == true">mdi-chevron-right</v-icon>
                </v-btn>
            </v-row>
        </v-list-item>


        <v-divider></v-divider>

        <v-list dense>
            <v-list-item-group>
                <v-list-item @click="dashboard()">

                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item @click="navigate('/admin/users')" link>

                    <v-list-item-icon>
                        <v-icon dense>fas fa-users</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>User Management</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item @click="navigate('/admin/courses')" link>

                    <v-list-item-icon>
                        <v-icon dense>mdi-calendar-month</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Course Management</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item @click="navigate('/admin/discount')">

                    <v-list-item-icon>
                        <v-icon dense>mdi-currency-gbp</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Discount</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item @click="navigate('/admin/assets')" link>

                    <v-list-item-icon>
                        <v-icon dense>fas fa-paw-claws</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Asset Management</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item @click="navigate('/admin/settings')" link>

                    <v-list-item-icon>
                        <v-icon dense>mdi-cog</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Site Settings</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list-item-group>

        </v-list>

    </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "AdminMenu",
    computed: {
        ...mapGetters([
            'getUserLevel'
        ]),
        drawer: {
            get() {
                return this.$store.getters.getDrawer
            },
            set(value) {
                this.$store.dispatch('setDrawer', value)
            }
        },
        permanent() {
            if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.md) {
                return false;
            }
            return true;
        }
    },
    data: () => ({
        mini: true,
    }),
    methods: {
        ...mapActions('members', [
            'setMemberData'
        ]),
        ...mapActions('users', [
            'getUsersData'
        ]),
        ...mapActions('courses', [
            'getCoursesData'
        ]),
        ...mapActions('discounts', [
            'getDiscountData'
        ]),
        ...mapActions('assets', [
            'getAssetsData'
        ]),
        ...mapActions('settings', [
            'getSettingsData'
        ]),
        navigate(path) {
            if (path == this.$route.path) {
                if (path == '/admin/users') {
                    this.getUsersData();
                } else if (path == '/admin/courses') {
                    this.getCoursesData();
                } else if (path == '/admin/discount') {
                    this.getDiscountData();
                } else if (path == '/admin/assets') {
                    this.getAssetsData();
                } else if (path == '/admin/settings') {
                    this.getSettingsData();
                }
            } else {
                this.$router.push(path);
            }
        },
        dashboard() {
            if (this.$route.name !== 'members') {
                this.$router.push({ name: 'members' });
            } else {
                this.setMemberData();
            }
        }
    }
};
</script>