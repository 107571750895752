import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const courses = {
    namespaced: true,
    state: () => ({
        courses: [],
        classes: [],
        groups: []
    }),

    mutations: {
        setCoursesData(state, response) {
            state.courses = response;
        },
        setClassesData(state, response) {
            state.classes = response;
        },
        setGroupsData(state, response) {
            state.groups = response;
        },
        addCourse(state, response) {
            state.courses.push(response);
        }
    },
    actions: {
        async getCoursesData({ commit }) {
            await axiosInstance.get('admin/courses/')
                .then(response => {
                    commit('setCoursesData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async addCourse({ commit }, payload) {
            await axiosInstance.post('admin/courses/create/', payload)
                .then(response => {
                    commit('addCourse', response.data)
                })
        },
        async getClassesData({ commit }, guid) {
            await axiosInstance.get('admin/courses/classes/' + guid + '/')
                .then(response => {
                    commit('setClassesData', response.data);
                })
        },
        async getGroupsData({ commit }, guid) {
            await axiosInstance.get('admin/courses/groups/' + guid + '/')
                .then(response => {
                    commit('setGroupsData', response.data);
                })
        },
    }
}

export default courses