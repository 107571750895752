import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const members = {
    namespaced: true,
    state: () => ({
        mycourses: [],
        mentorcourses: [],
        availablecourses: [],
        acceptedcourses: [],
        notificationtypes: [],
        notifications: [],
        availdetails: [],
        availmentors: [],
        availupcoming: [],
        applications: [],
    }),

    mutations: {
        setMemberData(state, response) {
            state.mycourses = response.usercourses;
            state.acceptedcourses = response.acceptedcourses;
            state.mentorcourses = response.mentorcourses;
            state.availablecourses = response.availablecourses;
            state.applications = response.applications;
        },
        setUserNotifications(state, response) {
            state.notifications = response;
        },
        setNotificationType(state, response) {
            state.notificationtypes = response;
        },
        markNotificationRead(state, guid) {
            let index = state.notifications.findIndex(s => s.guid == guid)
            state.notifications[index].seenstatus = 1
        },
        markAllRead(state) {
            state.notifications = []
        },
        setAvailCouresDetails(state, response) {
            state.availdetails = response.details;
            state.availmentors = response.mentors;
            state.availupcoming = response.upcoming;
        },
        applyToCourse(state, response) {
            state.applications.push(response)
        }
    },
    actions: {
        async setMemberData({ commit }) {
            await axiosInstance.get('members/')
                .then(response => {
                    commit('setMemberData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async getNotifications({ commit }) {
            await axiosInstance.get('members/notifications/')
                .then(function (response) {
                    commit('setUserNotifications', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async setUserNotifications({ dispatch }) {
            dispatch('getNotifications');
            setInterval(() => {
                if (sessionStorage.loginToken) {
                    dispatch('getNotifications')
                }
            }, 60000);
        },
        async setNotificationType({ commit }) {
            await axiosInstance.get('members/notificationtype/')
                .then(function (response) {
                    commit('setNotificationType', response.data);
                })
        },
        async markNotificationRead({ commit }, guid) {
            await axiosInstance.put('members/readnotification/' + guid + '/')
                .then(
                    commit('markNotificationRead', guid)
                )
        },
        async markAllRead({ commit }) {
            await axiosInstance.put('members/markallread/')
                .then(
                    commit('markAllRead')
                )
        },
        async setAvailCourseDetails({ commit }, guid) {
            await axiosInstance.get('members/availcoursedetails/' + guid + '/')
                .then(response => {
                    commit('setAvailCouresDetails', response.data);
                })
        },
        async applyToCourse({ commit }, payload) {
            await axiosInstance.post('members/course/apply/', payload)
                .then(response => {
                    commit('applyToCourse', response.data);
                })
        }
    },
    getters: {
        getNotifications(state) {
            return state.notifications;
        },
        getUnreadNotifications(state) {
            return state.notifications.filter(n => n.seenstatus == 0);
        },
        getNotificationIcon: (state) => (id) => {
            let icons = state.notificationtypes.filter(n => n.id === id).map(i => { return i.icon });
            return icons[0]
        },
        getNotificationName: (state) => (id) => {
            let name = state.notificationtypes.filter(n => n.id === id).map(i => { return i.name });
            return name[0]
        },
        getNotificationLink: (state) => (id) => {
            let name = state.notificationtypes.filter(n => n.id === id).map(i => { return i.link });
            return name[0]
        },
        getMentorWeek: (state) => (guid) => {
            let course = state.mentorcourses.filter(c => c.guid === guid).map(d => { return d.start });
            let start = new Date(course[0]);
            let now = new Date();
            var time_difference = now.getTime() - start.getTime();
            var days_difference = time_difference / (1000 * 60 * 60 * 24 * 7);
            return Math.floor(days_difference + 1)
        },
        getCourseWeek: (state) => (guid) => {
            let course = state.mycourses.filter(c => c.guid === guid).map(d => { return d.start });
            let start = new Date(course[0]);
            let now = new Date();
            var time_difference = now.getTime() - start.getTime();
            var days_difference = time_difference / (1000 * 60 * 60 * 24 * 7);
            return Math.floor(days_difference + 1)
        }
    }
}

export default members