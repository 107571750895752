// frontend\src\plugins\connectionBuilder.js
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
});

if (localStorage.getItem('loginToken')) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('loginToken')}`;
}

if (localStorage.getItem('impersonationToken')) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('impersonationToken')}`;
}

export default axiosInstance;