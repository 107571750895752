// members\src\router\index.js
import Vue from 'vue'
import Router from 'vue-router'
import Admin from '../views/admin/AdminView.vue'
import Members from '../views/members/MemberView.vue'

import store from '../store/index.js';

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.getIsUserLoggedIn) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    store.dispatch('checksession')
    if (store.getters.getIsUserLoggedIn) {
        store.dispatch('setLink', null);
        next()
        return
    }
    else {
        store.dispatch('setLink', to.fullPath);
    }
    next('/login')
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/admin',
            component: Admin,
            children: [
                {
                    path: '/',
                    name: 'admin',
                    component: () => import('@/views/admin/DashboardView'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'users',
                    name: 'adminusers',
                    component: () => import('@/views/admin/UsersView'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'courses',
                    name: 'admincourses',
                    component: () => import('@/views/admin/CoursesView'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'course/:guid',
                    name: 'admincourse',
                    component: () => import('@/views/admin/EditCourseView'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'discount',
                    name: 'admindiscount',
                    component: () => import('@/views/admin/DiscountView'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'assets',
                    name: 'adminassets',
                    component: () => import('@/views/admin/AssetsView'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'settings',
                    name: 'adminsettings',
                    component: () => import('@/views/admin/SiteSettingsView'),
                    beforeEnter: ifAuthenticated,
                },
            ],
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/',
            component: Members,
            children: [
                {
                    path: '/',
                    name: 'members',
                    component: () => import('@/views/members/MainView'),
                    beforeEnter: ifAuthenticated,
                },
            ],
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/mentor/:groupguid',
            component: () => import('@/views/members/MentorView'),
            children: [
                {
                    path: '/',
                    name: 'mentor',
                    component: () => import('@/components/members/mentor/mentor'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'week/:weekid',
                    name: 'mentorweek',
                    component: () => import('@/components/members/mentor/week'),
                    beforeEnter: ifAuthenticated,
                },
            ],
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/course/:groupguid',
            component: () => import('@/views/members/CourseView'),
            children: [
                {
                    path: '/',
                    name: 'course',
                    component: () => import('@/components/members/course/intro'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'assets',
                    name: 'assets',
                    component: () => import('@/components/members/course/assets'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'resources',
                    name: 'resources',
                    component: () => import('@/components/members/course/resources'),
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'week/:weekid',
                    name: 'week',
                    component: () => import('@/components/members/course/week'),
                    beforeEnter: ifAuthenticated,
                },
            ],
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/RegisterView'),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/LoginView'),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/verify/:code',
            name: 'verify',
            component: () => import('@/views/VerifyView'),
            beforeEnter: ifNotAuthenticated,
        },
        { path: '*', redirect: '/members' }
    ],
})
