<!-- 
frontend\src\views\admin\AdminView.vue
-->
<template>
    <v-container fluid>
        <router-view></router-view>
    </v-container>
</template>

<script>
    export default {
        name: 'AdminView',
        components: {
        
        }
    }
</script>