import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router'

const users = {
    namespaced: true,
    state: () => ({
        guid: '',
        users: [],
        levels: [
            { id: 1, color: 'green', role: 'User' },
            //{ id: 2, color: 'red', role: 'N/A'},
            { id: 3, color: 'blue', role: 'TD' },
            { id: 4, color: 'orange', role: 'Senior TD' },
            { id: 5, color: 'purple', role: 'Admin' },
        ],
        usercomputers: [],
        usercourses: [],
        userpayment: [],
        userroles: [],
        discord: '',
        checkrig: []
    }),

    mutations: {
        setUserGuid(state, response) {
            state.guid = response;
        },
        setUsersData(state, response) {
            state.users = response.users;
        },
        setUserInfo(state, response) {
            state.usercomputers = response.computers;
            state.usercourses = response.courses;
            state.userpayment = response.payments;
            state.userroles = response.roles;
            state.discord = response.discord;
        },
        addNewUser(state, response) {
            state.users.unshift(response);
        },
        addCourse(state, response) {
            state.usercourses.push(response);
        },
        deleteUser(state, index) {
            state.users.splice(index, 1);
        },
        deleteUserComputers(state, guid) {
            let index = state.usercomputers.findIndex(c => c.guid == guid)
            state.usercomputers.splice(index, 1)
        },
        updateUserDetails(state, response) {
            let index = state.users.findIndex(c => c.guid == response.guid)
            state.users.splice(index, 1, response);
        },
        updateCourse() {

        },
        checkRigAccess(state, response) {
            state.checkrig = response;
        },
        setDiscord(state, value) {
            state.discord = value;
        }
    },
    actions: {
        async getUsersData({ commit }) {
            await axiosInstance.get('admin/users/')
                .then(response => {
                    commit('setUsersData', response.data);
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async getUserInfo({ commit }, guid) {
            await axiosInstance.get('admin/users/info/' + guid + '/')
                .then(response => {
                    commit('setUserInfo', response.data);
                })
        },
        async setUserGuid({ commit }, guid) {
            commit('setUserGuid', guid)
        },
        async addNewUser({ commit }, payload) {
            await axiosInstance.post('admin/users/createuser/', payload)
                .then(response => {
                    commit('addNewUser', response.data);
                })
        },
        async notifyUser(_, guid) {
            await axiosInstance.put('admin/users/notify/' + guid + '/')
        },
        async deleteUser({ commit, state }, index) {
            let guid = state.users[index].guid;
            await axiosInstance.delete('admin/users/delete/' + guid + '/')
                .then(
                    commit('deleteUser', index)
                )
        },
        async resetUserPassword({ state }, index) {
            let guid = state.users[index].guid;
            await axiosInstance.put('admin/users/password/reset/' + guid + '/')
        },
        async deleteComputer({ commit }, guid) {
            await axiosInstance.put('admin/users/computer/deactivate/' + guid + '/')
                .then(response => {
                    commit('deleteUserComputers', response.data);
                })
        },
        async updateUserDetails({ commit }, payload) {
            await axiosInstance.put('admin/users/updateuser/', payload)
                .then(response => {
                    commit('updateUserDetails', response.data);
                })
        },
        async impersonateUser({ dispatch }, guid) {
            dispatch('impersonateUser', guid, { root: true });
        },
        async checkRigAccess({ commit, dispatch }, guid) {
            await axiosInstance.get('admin/users/check/rigaccess/' + guid + '/')
                .then(response => {
                    commit('checkRigAccess', response.data);
                    dispatch('toggleSnackBarvisibleAction', null, { root: true });
                })
        },
        async addUserToCourse({ commit }, payload) {
            await axiosInstance.post('admin/users/addcourse/', payload)
                .then(response => {
                    commit('addCourse', response.data);
                })
        },
        async setUserCourseStatus({ commit }, payload) {
            await axiosInstance.post('admin/users/coursestatus/', payload)
                .then(response => {
                    commit('updateCourse', response.data);
                })
        },
        async setUserCourseBlocked({ commit }, payload) {
            await axiosInstance.post('admin/users/courseblocked/', payload)
                .then(response => {
                    commit('updateCourse', response.data);
                })
        },
    },
    getters: {
        getCheckData(state) {
            return state.checkrig;
        }
    }
}

export default users