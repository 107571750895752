<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <MainNavbar />
        <AdminMenu v-if="getIsUserLoggedIn" />
        <v-main>
            <router-view :key="$route.path" />
        </v-main>
        <!--<UpdateSnackbar />-->
    </v-app>
</template>

<script>
import MainNavbar from '@/components/core/Navbar';
import AdminMenu from '@/components/core/Menu';
//import UpdateSnackbar from '@/components/core/UpdateSnackbar';

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    created() {
        this.getGeoData();
        if (localStorage["impersonationToken"]) {
            this.setImpersonateUser();
            sessionStorage.impersonationToken = localStorage["impersonationToken"];
        }
        if (localStorage["loginToken"]) {
            sessionStorage.loginToken = localStorage["loginToken"];
            this.setUserInfo();
            this.setNotificationType();
            this.setUserNotifications();
        }
        this.$vuetify.theme.options.customProperties = true
        this.$vuetify.theme.themes.dark.primary = '#809a39'
        this.$vuetify.theme.themes.dark.complete = '#153616'
        this.$vuetify.theme.themes.dark.accent = '#424242'
        this.$vuetify.theme.themes.dark.highlight = '#000000'
        this.$vuetify.theme.themes.light.primary = '#809a39'
        this.$vuetify.theme.themes.light.complete = '#ccffce'
        this.$vuetify.theme.themes.light.background = '#f1f1f1'
        this.$vuetify.theme.themes.light.accent = '#EEEEEE'
        this.$vuetify.theme.themes.light.highlight = '#ffffff'
    },
    updated() {
        this.$vuetify.theme.dark = this.getDarkMode;
    },
    name: 'App',
    computed: {
        ...mapState([
        ]),
        ...mapGetters([
            'getIsUserLoggedIn',
            'getDarkMode'
        ]),
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        }
    },
    components: {
        MainNavbar,
        AdminMenu,
        //UpdateSnackbar,
    },
    methods: {
        ...mapActions([
            'setUserInfo',
            'setImpersonateUser',
            'getGeoData'
        ]),
        ...mapActions('members', [
            'setUserNotifications',
            'setNotificationType'
        ])
    }
};
</script>