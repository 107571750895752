<template>
  <div class="pr-2" v-if="getIsUserLoggedIn">
    <v-menu
      class="toolbar-menu-item ma-0 pa-0"
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          large
          text
          slot="activator"
          :ripple="false"
          v-bind="attrs"
          v-on="on"
        >
          <font-awesome-icon
            v-if="getUnreadNotifications.length == 0"
            icon="fa-solid fa-bell"
            size="lg"
          />
          <v-badge
            class="mt-2 mr-3"
            v-if="getUnreadNotifications.length >= 1"
            color="primary"
            :content="getNotificationCount()"
          >
            <font-awesome-icon
              icon="fa-solid fa-bell"
              size="lg"
              beat
              :style="{ color: '#809a39' }"
            />
          </v-badge>
        </v-btn>
      </template>
      <v-card class="pa-0 ma-0" width="220">
        <v-list-item-content
          v-if="getNotifications.length == 0"
          class="justify-center mb-0 pb-0"
        >
          <div class="mx-auto text-center pa-0">
            <v-list class="ma-0 pa-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>All caught up!</v-list-item-title>
                  <v-list-item-subtitle
                    >No new notifications.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-list-item-content>
        <v-list-item-content
          v-if="getNotifications.length >= 1"
          class="justify-center mb-0 pb-0"
        >
          <div class="mx-auto text-center pa-0">
            <v-list class="ma-0 pa-0 overflow-auto" max-height="500">
              <v-list-item
                two-line
                v-for="notification in getNotifications"
                :key="notification.guid"
                @click="clickNotification(notification.guid)"
              >
                <v-list-item-icon v-if="notification.seenstatus == 0">
                  <v-icon small color="primary">{{
                    getNotificationIcon(notification.type)
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-icon v-if="notification.seenstatus == 1">
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-if="notification.seenstatus == 0"
                    class="font-weight-black primary--text"
                    >{{
                      getNotificationName(notification.type)
                    }}</v-list-item-title
                  >
                  <v-list-item-title v-if="notification.seenstatus == 1">{{
                    getNotificationName(notification.type)
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ notificationTime(notification.created) }} ago
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list class="ma-0 pa-0">
              <v-list-item v-if="getUnreadNotifications.length >= 1">
                <v-row align="center" justify="space-around">
                  <v-btn text color="primary" @click="markAllRead()">
                    Mark all as Read
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-list>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserNotification",
  components: {},
  computed: {
    ...mapGetters("members", [
      "getNotifications",
      "getUnreadNotifications",
      "getNotificationIcon",
      "getNotificationName",
      "getNotificationLink",
    ]),
    ...mapGetters(["getIsUserLoggedIn"]),
  },
  methods: {
    ...mapActions("members", ["markNotificationRead", "markAllRead"]),
    getNotificationCount() {
      return this.getNotifications.filter((n) => {
        if (n.seenstatus == 0) {
          return n;
        }
      }).length;
    },
    filteredNotifications() {
      if (this.getNotifications.length > 10) {
        return this.getNotifications.filter((n) => {
          if (n.seenstatus == 0) {
            return n;
          }
        });
      } else {
        return this.getNotifications;
      }
    },
    clickNotification(guid) {
      let notification = this.getNotifications.find((n) => {
        if (n.guid == guid) {
          return n;
        }
      });
      let path =
        "/" + this.getNotificationLink(notification.type) + notification.link;
      this.$router.push(path);
      this.markNotificationRead(guid);
    },
    notificationTime(date) {
      let now = new Date();
      let check = new Date(date);
      let time;
      // standardize for UTC +1 hour for BST.
      let UTCnow = now.getTime() + 3600000 + now.getTimezoneOffset() * 60000;
      // calculate the time difference of two dates JavaScript
      var diffTime = (UTCnow - check) / 1000;

      if (diffTime < 0) {
        diffTime == 0;
      }

      if (diffTime >= 604800) {
        time = parseInt(diffTime / 604800) + " weeks";
      } else if (diffTime >= 86400) {
        time = parseInt(diffTime / 86400) + " days";
      } else if (diffTime >= 3600) {
        time = parseInt(diffTime / 3600) + " hours";
      } else if (diffTime >= 60) {
        time = parseInt(diffTime / 60) + " minutes";
      } else {
        time = parseInt(diffTime) + " seconds";
      }

      return time;
    },
  },
};
</script>