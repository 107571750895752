import axiosInstance from '@/plugins/connectionBuilder.js'
import Tus from '@uppy/tus'
import router from '@/router'

const editcourse = {
    namespaced: true,
    state: () => ({
        guid: null,
        name: null,
        upload: false,
        uploadprogress: 0,
        duration: 0,
        course: [],
        classes: [],
        groups: [],
        mentors: [],
        weeks: [],
        students: [],
        applicants: [],
        payments: [],
        course_assets: [],
        asset_family: [],
        asset_type: [],
        demos: [],
        demo_module: [],
        demo_subcat: [],
    }),

    mutations: {
        setGuid(state, guid) {
            state.guid = guid;
        },
        setCourseData(state, response) {
            state.name = response.details.name;
            state.duration = response.details.duration;
            state.course = response.details;
            state.classes = response.classes;
            state.groups = response.groups;
            state.mentors = response.mentors;
            state.weeks = response.weeks;
            state.students = response.students;
            state.applicants = response.applicants;
            state.payments = response.payments;
            state.course_assets = response.course_assets
            state.asset_family = response.asset_family;
            state.asset_type = response.asset_type;
            state.demos = response.demos;
            state.demo_module = response.demomodule;
            state.demo_subcat = response.demosubcat;
        },
        createClass(state, response) {
            state.classes.push(response);
        },
        editClass(state, response) {
            let index = state.classes.findIndex(obj => obj.guid == response.guid);
            state.classes[index].start = response.start;
        },
        updateClasses(state) {
            state.classes = state.classes.map(c => {
                let container = {}
                container = c
                let status
                let today = new Date();
                let start = new Date(c.start);
                let end = new Date(c.start);
                end.setDate(end.getDate() + state.duration * 7)
                if (today >= start) {
                    if (today > end) {
                        status = 2;
                    } else {
                        status = 1;
                    }
                } else {
                    status = 0;
                }
                container.status = status
                return container;
            });
        },
        updateDetails(state, details) {
            state.name = details.name;
            state.duration = details.duration;
        },
        deleteClass(state, guid) {
            let index = state.classes.findIndex(w => w.guid == guid)
            state.classes.splice(index, 1)
        },
        createGroup(state, response) {
            state.groups.push(response);
        },
        deleteGroup(state, guid) {
            let index = state.groups.findIndex(w => w.guid == guid)
            state.groups.splice(index, 1)
        },
        addWeek(state, response) {
            state.weeks.push(response);
        },
        updateWeek(state, response) {
            let index = state.weeks.findIndex(obj => obj.guid == response.guid);
            state.weeks[index].title = response.title;
            state.weeks[index].submission = response.submission;
            state.weeks[index].description = response.description;
        },
        deleteWeek(state, guid) {
            let index = state.weeks.findIndex(w => w.guid == guid)
            state.weeks.splice(index, 1)
        },
        addDemo(state, response) {
            state.demos.push(response);
        },
        updateDemo(state, response) {
            let index = state.demos.findIndex(obj => obj.guid == response.guid);
            state.demos[index].title = response.title;
            state.demos[index].category_guid = response.category_guid;
            state.demos[index].subcat_guid = response.subcat_guid;
        },
        deleteDemo(state, guid) {
            let index = state.demos.findIndex(d => d.guid == guid)
            state.demos.splice(index, 1)
        },
        addDemoModule(state, response) {
            state.demo_module.push(response);
        },
        updateDemoModule(state, response) {
            let index = state.demo_module.findIndex(obj => obj.guid == response.guid);
            state.demo_module[index].week_guid = response.week_guid;
            state.demo_module[index].title = response.title;
            state.demo_module[index].description = response.description;
        },
        deleteDemoModule(state, guid) {
            let index = state.demo_module.findIndex(m => m.guid == guid)
            state.demo_module.splice(index, 1)
        },
        addDemoSubcat(state, response) {
            state.demo_subcat.push(response);
        },
        updateDemoSubcat(state, response) {
            let index = state.demo_subcat.findIndex(obj => obj.guid == response.guid);
            state.demo_subcat[index].week_guid = response.week_guid;
            state.demo_subcat[index].name = response.name;
        },
        deleteDemoSubcat(state, guid) {
            let index = state.demo_subcat.findIndex(s => s.guid == guid)
            state.demo_subcat.splice(index, 1)
        },
        acceptapplicant(state, guid) {
            let index = state.applicants.findIndex(a => a.guid == guid)
            state.applicants[index].status = 1;
        },
        transferapplicant(state, guid) {
            let index = state.applicants.findIndex(a => a.guid == guid)
            state.applicants[index].status = 3;
        },
        rejectapplicant(state, guid) {
            let index = state.applicants.findIndex(a => a.guid == guid)
            state.applicants[index].status = 2;
        },
        resetapplicant(state, guid) {
            let index = state.applicants.findIndex(a => a.guid == guid)
            state.applicants[index].status = 0;
        },
        addSyncSketch(state, guid, response) {
            let index = state.groups.findIndex(obj => obj.guid == guid);
            state.groups[index].syncsketch = response;
        },
        setStudentStatus(state, response) {
            let index = state.students.findIndex(obj => obj.guid == response.guid);
            state.students[index].student_status = response.student_status;
        },
        setStudentBlocked(state, response) {
            let index = state.students.findIndex(obj => obj.guid == response.guid);
            state.students[index].blocked = response.blocked;
        },
        addAssetFamily(state, response) {
            state.course_assets.push(response);
        },
        removeAssetFamily(state, guid) {
            let index = state.course_assets.findIndex(obj => obj.guid == guid);
            state.course_assets.splice(index, 1)
        },
        addPayment(state, response) {
            state.payments.push(response);
        },
        addStudent(state, response) {
            state.students.unshift(response);
        },
        addCost(state, response) {
            let index = state.students.findIndex(obj => obj.guid == response.guid);
            state.students[index].price = response.price;
            state.students[index].installments = response.installments;
        }
    },

    actions: {
        async setGuid({ commit }, guid) {
            commit('setGuid', guid);
        },
        async getCourseData({ commit, state }) {
            await axiosInstance.get('admin/courses/' + state.guid + '/')
                .then(response => {
                    commit('setCourseData', response.data);
                    commit('updateClasses');
                }).catch(
                    function (error) {
                        if (error.request.status == 401) {
                            commit('setLogout', null, { root: true });
                            router.push('/login').catch((e) => e);
                        }
                    }
                )
        },
        async createClass({ commit, dispatch }, payload) {
            let classinfo = { "course": payload.course_guid, "start": payload.start }
            await axiosInstance.post('admin/courses/classes/create/', classinfo)
                .then(response => {
                    commit('createClass', response.data);
                    commit('updateClasses');
                    if (payload.groups.length >= 1) {
                        let addClass = { 'class_guid': response.data.guid, 'groups': payload.groups }
                        dispatch('addClassGroups', addClass)
                    }
                })
        },
        async editClass({ commit }, payload) {
            await axiosInstance.put('admin/courses/classes/edit/', payload)
                .then(response => {
                    commit('editClass', response.data);
                })
        },
        async addPayment({ commit }, payload) {
            await axiosInstance.put('admin/courses/student/addpayment/', payload)
                .then(response => {
                    commit('addPayment', response.data);
                })
        },
        async addCost({ commit }, payload) {
            await axiosInstance.put('admin/courses/student/cost/', payload)
                .then(response => {
                    commit('addCost', response.data);
                })
        },
        async addClassGroups({ commit }, payload) {
            for (let i = 0; i < payload.groups.length; i++) {
                let groupinfo = { "classguid": payload.class_guid, "mentor_guid": payload.groups[i].mentor_guid };
                axiosInstance.post('admin/courses/groups/create', groupinfo)
                    .then(response => {
                        commit('createGroup', response.data);
                    })
            }
        },
        async deleteClass({ commit }, guid) {
            await axiosInstance.delete('admin/courses/classes/delete/' + guid + '/')
                .then(
                    commit('deleteClass', guid)
                )
        },
        async deleteGroup({ commit }, guid) {
            await axiosInstance.delete('admin/courses/groups/delete/' + guid + '/')
                .then(
                    commit('deleteGroup', guid)
                )
        },
        async newWeek({ commit, dispatch, state }, payload) {
            state.upload = true;
            let file = payload.videofile;
            let formData = new FormData();
            formData.append('file', payload.pdffile)
            let newweek = { "course_guid": state.guid, "title": payload.title, "weekid": payload.weekid, "submission": payload.submission, "description": payload.description };
            axiosInstance.post('admin/courses/week/create/', newweek)
                .then(response => {
                    if (payload.pdffile != "") {
                        axiosInstance.post('admin/courses/week/pdf/' + response.data.guid + '/', formData,
                            {
                                onUploadProgress: function (progressEvent) {
                                    state.uploadprogress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                                }.bind(state)
                            })
                        state.uploadprogress = 0;
                    }

                    commit('addWeek', response.data);
                    let upload = { "guid": response.data.upload_guid, "title": payload.title, "file": file }
                    dispatch('uploadStream', upload);
                }).catch(error => {
                    console.log(error)
                })
        },
        /*
        async newWeek({ commit, state }, payload ) {
            state.upload = true;
            let files = [];
            files.push(payload.videofile);
            files.push(payload.pdffile);
            let formData = new FormData();
            for (const i of Object.keys(files)) {
                formData.append('files', files[i])
            }
            formData.append('course_guid', state.guid);
            formData.append('title', payload.title);
            formData.append('weekid', payload.weekid);
            formData.append('description', payload.description);
            axiosInstance.post('admin/courses/week/create', formData,
            {
                onUploadProgress: function( progressEvent ) {
                    state.uploadprogress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                }.bind(state)
            }
            ).then(response => {
                commit('addWeek', response.data);
                state.upload = false;
                state.uploadprogress = 0;
            }).catch(error => {
                console.log(error)
            })
        },*/
        async updateWeek({ commit }, payload) {
            await axiosInstance.put('admin/courses/week/update/', payload)
                .then(
                    commit('updateWeek', payload)
                )
        },
        async deleteWeek({ commit }, guid) {
            await axiosInstance.delete('admin/courses/week/delete/' + guid + '/')
                .then(
                    commit('deleteWeek', guid)
                )
        },
        async newDemo({ commit, dispatch, state }, payload) {
            let file = payload.file
            let demo = { "course_guid": state.guid, "title": payload.title, "category_guid": payload.category_guid, "subcat_guid": payload.subcat_guid }
            state.upload = true
            axiosInstance.post('admin/courses/demo/create/', demo)
                .then(response => {
                    commit('addDemo', response.data);
                    let upload = { "guid": response.data.upload_guid, "title": payload.title, "file": file };
                    dispatch('uploadStream', upload);
                }).catch(error => {
                    console.log(error)
                })
        },
        async uploadStream({ dispatch, state }, upload) {
            dispatch(
                'uppy/init',
                {
                    id: 'uppy',
                    autoProceed: false,
                    restrictions: { maxNumberOfFiles: 1 },
                    debug: true
                },
                { root: true }
            );
            let plugin = Tus
            let options = {
                endpoint: process.env.VUE_APP_BASE_API_URL + '/cloudflare/tus/' + upload.guid,
                chunkSize: 52428800,
                retryDelays: [0, 1000, 3000, 5000],
            }
            dispatch(
                'uppy/use',
                { plugin, options },
                { root: true }
            )
            let data = { allowedOrigins: ["monsteremporium.com", "*.monsteremporium.com", "monsteremporium.co.uk", "*.monsteremporium.co.uk"] }
            dispatch(
                'uppy/setMeta',
                data,
                { root: true }
            )
            let fileObject = {
                name: upload.title,
                type: upload.file.mimetype,
                data: upload.file,
            }
            dispatch(
                'uppy/addFile',
                fileObject,
                { root: true }
            );
            dispatch(
                'uppy/upload',
                null,
                { root: true }
            );
            let event = 'progress';
            function progress(progress) {
                state.uploadprogress = progress;
            }
            let callback = progress
            dispatch(
                'uppy/on',
                { event, callback },
                { root: true }
            )
            event = 'upload-success'
            function upload_success() {
                dispatch(
                    'uppy/close',
                    null,
                    { root: true }
                );
                state.upload = false;
                state.uploadprogress = 0;
            }
            callback = upload_success
            dispatch(
                'uppy/on',
                { event, callback },
                { root: true }
            )
        },

        /*
        async newDemo({ commit, state }, payload ) {
            state.upload = true;
            let formData = new FormData();
            formData.append('file', payload.file);
            formData.append('course_guid', state.guid);
            formData.append('category_guid', payload.module_guid);
            formData.append('subcat_guid', payload.subcat_guid);
            formData.append('title', payload.title);
            axiosInstance.post('admin/courses/demo/create', formData,
            {
                onUploadProgress: function( progressEvent ) {
                    state.uploadprogress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                }.bind(state)
            }
            ).then(response => {
                commit('addDemo', response.data);
                state.upload = false;
                state.uploadprogress = 0;
            }).catch(error => {
                console.log(error)
            })
        },*/
        async updateDemo({ commit }, payload) {
            await axiosInstance.put('admin/courses/demo/update/', payload)
                .then(
                    commit('updateDemo', payload)
                )
        },
        async deleteDemo({ commit }, guid) {
            await axiosInstance.delete('admin/courses/demo/delete/' + guid + '/')
                .then(
                    commit('deleteDemo', guid)
                )
        },
        async newDemoModule({ commit }, payload) {
            return await new Promise((resolve, reject) => {
                axiosInstance.post('admin/courses/demo/module/create/', payload)
                    .then(response => {
                        commit('addDemoModule', response.data);
                        resolve(response.data.guid);
                    }, error => {
                        reject(error);
                    })
            })
        },
        async updateDemoModule({ commit }, payload) {
            await axiosInstance.put('admin/courses/demo/module/update/', payload)
                .then(
                    commit('updateDemoModule', payload)
                )
        },
        async deleteDemoModule({ commit }, guid) {
            await axiosInstance.delete('admin/courses/demo/module/delete/' + guid + '/')
                .then(
                    commit('deleteDemoModule', guid)
                )
        },
        async newDemoSubcat({ commit }, payload) {
            return await new Promise((resolve, reject) => {
                axiosInstance.post('admin/courses/demo/subcat/create/', payload)
                    .then(response => {
                        commit('addDemoSubcat', response.data);
                        resolve(response.data.guid);
                    }, error => {
                        // http failed, let the calling function know that action did not work out
                        reject(error);
                    })
            })
        },
        async updateDemoSubcat({ commit }, payload) {
            await axiosInstance.put('admin/courses/demo/subcat/update/', payload)
                .then(
                    commit('updateDemoSubcat', payload)
                )
        },
        async deleteDemoSubcat({ commit }, guid) {
            await axiosInstance.delete('admin/courses/demo/subcat/delete/' + guid + '/')
                .then(
                    commit('deleteDemoSubcat', guid)
                )
        },
        async acceptapplicant({ commit }, payload) {
            await axiosInstance.put('admin/courses/applicants/accept/', payload)
                .then(
                    commit('acceptapplicant', payload.guid)
                )
        },
        async transferapplicant({ commit }, guid) {
            await axiosInstance.put('admin/courses/applicants/transfer/' + guid + '/')
                .then(response => {
                    commit('addStudent', response.data);
                    commit('transferapplicant', guid);
                }) 
        },
        async rejectapplicant({ commit }, guid) {
            await axiosInstance.put('admin/courses/applicants/reject/' + guid + '/')
                .then(
                    commit('rejectapplicant', guid)
                )
        },
        async resetapplicant({ commit }, guid) {
            await axiosInstance.put('admin/courses/applicants/reset/' + guid + '/')
                .then(
                    commit('resetapplicant', guid)
                )
        },
        async updateDetails({ commit }, payload) {
            await axiosInstance.put('admin/courses/details/update/', payload)
                .then(
                    commit('updateDetails', payload)
                )
        },
        async setStudentStatus({ commit }, payload) {
            commit('setStudentStatus', payload);
        },
        async setStudentBlocked({ commit }, payload) {
            commit('setStudentBlocked', payload);
        },
        async addSyncSketch({ commit }, groupguid) {
            await axiosInstance.get('admin/courses/syncsketch/' + groupguid + '/')
                .then(response => {
                    commit('addSyncSketch', groupguid, response.data);
                })
        },
        async addAssetFamily({ commit, state }, familyguid) {
            let payload = { "course_guid": state.guid, "family_guid": familyguid }
            await axiosInstance.post('admin/courses/courseasset/new/', payload)
                .then(response => {
                    commit('addAssetFamily', response.data);
                })
        },
        async removeAssetFamily({ commit }, guid) {
            await axiosInstance.delete('admin/courses/courseasset/delete/' + guid + '/')
                .then(
                    commit('removeAssetFamily', guid)
                )
        }
    },

    getters: {
        weekSorted(state) {
            return state.weeks.sort((a, b) => parseFloat(a.weekCourseId) - parseFloat(b.weekCourseId));
        },
        coursestatus(status) {
            return status + 1
        }
    }
}

export default editcourse