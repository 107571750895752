<template>
    <v-card>
        <v-dialog v-model="dialogDelete" max-width="500px" overlay-color="secondary">
            <v-card>
                <v-card-title class="text-h6">Are you sure you want to remove this computer?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="error" text @click="deleteItemConfirm">DELETE</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-form ref="form" @submit.prevent="updateprofile">
            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col class="my-0 py-0" cols="12" sm="6" md="6">
                            <v-text-field v-model="form.firstName" label="First Name*" :rules="[rules.required]"
                                required></v-text-field>
                        </v-col>
                        <v-col class="my-0 py-0" cols="12" sm="6" md="6">
                            <v-text-field v-model="form.lastName" label="Last Name*" :rules="[rules.required]"
                                required></v-text-field>
                        </v-col>
                        <v-col class="my-0 py-0" cols="12">
                            <v-text-field v-model="form.email" label="Email*" :rules="[rules.required, rules.email]"
                                required></v-text-field>
                            <v-text-field v-model="form.discord" label="Discord" clearable></v-text-field>
                            <v-menu v-model="date" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="form.dob" label="DOB" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="form.dob" @input="date = false"></v-date-picker>
                            </v-menu>
                            <v-autocomplete v-model="form.country" :items="geo" item-text="name" item-value="code"
                                :label="`Country`">
                            </v-autocomplete>
                            <p class="font-weight-light">
                                Computers
                            </p>
                            <v-chip v-for="computer in form.computers" :key="computer.guid" class="mr-3" label outlined
                                color="red" @click="deleteMyComputer(computer.guid)">
                                <v-icon left>
                                    mdi-close
                                </v-icon>
                                {{ computer.hostname }}
                            </v-chip>
                        </v-col>
                        <small class="mt-4 ml-4">*indicates required field</small>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closesettings">
                    Close
                </v-btn>
                <v-btn :disabled="!formIsValid || !formIsChanged || GetIsSnackbarVisible" color="primary" text
                    type="submit">
                    Save
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>


<script>
const profileForm = Object.freeze({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    country: '',
    discord: '',
    computers: [],
});

import { mapGetters, mapActions, mapState } from "vuex";

export default {
    Name: "UserProfile",
    components: {
    },
    data: () => ({
        form: { ...profileForm },
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            }
        },
        date: false,
        dialogDelete: false,
        deleteguid: ''
    }),
    computed: {
        ...mapState([
            'geo'
        ]),
        ...mapGetters([
            'getUserName',
            'getUserlastName',
            'getUserEmail',
            'getUserDiscord',
            'getUserDOB',
            'getUserCountry',
            'getUserComputers'
        ]),
        formIsValid() {
            return (
                this.form.firstName &&
                this.form.lastName &&
                this.form.email
            )
        },
        formIsChanged() {
            return (
                this.form.firstName != this.getUserName ||
                this.form.lastName != this.getUserlastName ||
                this.form.email != this.getUserEmail ||
                this.form.discord != this.getUserDiscord ||
                this.form.dob != this.getUserDOB ||
                this.form.country != this.getUserCountry ||
                this.form.computers != this.getUserComputers
            )
        }
    },
    methods: {
        ...mapActions([
            'settingsDialog',
            'deleteComputer',
            'updateUserInfo'
        ]),
        closesettings() {
            this.cleanprofile();
            this.settingsDialog(false);
        },
        updateprofile() {
            let payload = { "firstName": this.form.firstName, "lastName": this.form.lastName, "email": this.form.email, "dob": this.form.dob, "country": this.form.country, "discord": this.form.discord };
            this.updateUserInfo(payload);
        },
        deleteMyComputer(guid) {
            this.deleteguid = guid;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.deleteComputer(this.deleteguid);
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.deleteguid = ''
            })
        },
        cleanprofile() {
            this.form = { ...profileForm };
            this.$refs.form.resetValidation();
            this.form.firstName = this.getUserName;
            this.form.lastName = this.getUserlastName;
            this.form.email = this.getUserEmail;
            this.form.discord = this.getUserDiscord;
            this.form.dob = this.getUserDOB;
            this.form.country = this.getUserCountry;
            this.form.computers = this.getUserComputers;
        },
    },
    beforeMount() {
        this.form.firstName = this.getUserName;
        this.form.lastName = this.getUserlastName;
        this.form.email = this.getUserEmail;
        this.form.discord = this.getUserDiscord;
        this.form.dob = this.getUserDOB;
        this.form.country = this.getUserCountry;
        this.form.computers = this.getUserComputers;
    },
};
</script>