<template>
    <v-card>Settings not yet ready, please check back later.</v-card>
</template>


<script>
//import { mapGetters } from "vuex";

export default {
    Name: "PersonalSettings",
    components: {
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {
    }
};
</script>